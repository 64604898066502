<template>
    <el-dialog width="70vw" :visible.sync="visible" v-if="visible" destroy-on-close @closed="$emit('closed')" :align-center="true">
        <div>
            <div class="container">
                <!-- <div class="top_text" @click="goBack()">
                    <p>你的位置: 首页 / 商品详情</p>
                </div> -->
                <div class="content_individual">
                    <div class="content_details">
                        <div>
                            <div style="width:400px;height:400px;background: #FFFFFF;border-radius: 0.01rem;text-align: center">
                                <el-image :src="mainImgUrl" :preview-src-list="imgUrlList.length > 1 ? imgUrlList : [mainImgUrl]" style="height: 400px;" fit="contain">
                                </el-image>
                            </div>
                            <div>
                                <div style="background: #D8D8D8;height: 0.6rem;width: 28px;position: relative;top: 12px;cursor: pointer;text-align: center;">
                                    <i style="font-size: 0.2rem;display: inline-block;color: #FFFFFF;line-height: 0.6rem; " class="el-icon-arrow-left" @click="imgLeft()"></i>
                                </div>
                                <ul class="Img_ul">
                                    <li v-for="(item, index) in imgUrlList" :key="index" class="Img_li" :style="imgStyle" @click="changeImg(item, index)">
                                        <img :class="index === imgActiveIndex ? 'img_activeBorder' : ''" :src="item" style="width:50px;height:50px">
                                    </li>
                                </ul>
                                <div style="background: #D8D8D8;height: 0.6rem;width: 28px;position: relative;left: 370px;top: -116px;cursor: pointer;text-align: center;">
                                    <i style="font-size: 0.2rem;display: inline-block;color: #FFFFFF;line-height: 0.6rem;" class="el-icon-arrow-right" @click="imgRight()"></i>
                                </div>

                            </div>
                        </div>
                        <div class="content-main-box">
                            <div style="width: 100%">
                                <div class="content-main-box-text">
                                    {{ data.goods_name }}<span class="fuzhi" @click="fuzhi('goods_name')">复制</span>
                                </div>
                                <div class="flex flex-between flex-child-end margin-10 no-margin-right no-margin-bottom no-margin-left">
                                    <div style="width: 83%;margin-right: 1%;">
                                        <span class="mainHeight">{{ data.note }}</span>
                                    </div>
                                </div>
                                <div class="content-main-box-price flex flex-child-center" @click.stop style="margin-top: 0.3rem;" v-if="login_status">
                                    <span style="font-size: 0.16rem;font-weight: 500;color: #333333;margin-right: 40px;width: 40px;">价格</span>
                                    <span v-if="$store.state.turnOnOff">￥{{ price_profit }}</span>
                                    <span v-else>￥{{ old_price }}</span>
                                </div>
                                <div class="content-main-box-price flex flex-child-center" style="margin-top: 0.3rem;" v-else>
                                    <span style="font-size: 0.16rem;font-weight: 500;color: #333333;margin-right: 40px;width: 40px;">价格</span>
                                    <span style="color: #999999;font-size: 12px;">会员可查看价格</span>
                                </div>
                                <div style="margin-top: 0.3rem;" class="flex flex-align-content-start" v-if="data.attr_data && data.attr_data.length > 0">
                                    <span style="font-size: 0.16rem;font-weight: 500;color: #333333;margin-right: 40px;width: 40px;">规格</span>
                                    <div class="event_box">
                                        <div class="eventBox" @click="eventClick(item)" v-for="(item, index) in data.attr_data" :key='index' :class="attr_data_id == item.id ? 'backgroundxuan' : 'backgroundnone'">
                                            {{ item.attr_name }}
                                        </div>
                                    </div>
                                    <!--                                <el-select v-model="attr_data_id" placeholder="选择规格" style="width: 282px;" @change="attrChange($event)">-->
                                    <!--                                    <el-option v-for="item in data.attr_data" :key="item.id" :label="item.attr_name"-->
                                    <!--                                               :value="item.id"></el-option>-->
                                    <!--                                </el-select>-->
                                </div>
                                <div style="margin-top: 0.3rem;" class="flex flex-child-center">
                                    <span style="font-size: 0.16rem;font-weight: 500;color: #333333;margin-right: 40px;width: 40px;">数量</span>
                                    <div class="num_input flex">
                                        <el-button class="btn_minute" @click="btnMinute()">-</el-button>
                                        <div class="input">{{ cart_number }}</div>
                                        <el-button class="btn_add" @click="btnAdd()">+</el-button>
                                    </div>
                                </div>
                                <div class="flex flex-child-center" style="margin-top: 0.3rem;">
                                    <div style="font-size: 0.16rem;font-weight: 500;color: #333333;margin-right: 40px;width: 40px;"></div>
                                    <div class="flex flex-child-center flex-justify-content-center button-gwc" @click="addCart">
                                        <img src="../assets/images/gwc.png" style="margin-right: 0.05rem;">
                                        加入购物车
                                    </div>
                                    <div class="button-gwc flex flex-justify-content-center" style="background: #FFFFFF;
                                color: #4B83F0;border: 0.01rem solid #4B83F0;" @click="promptlyPay">立即购买</div>
                                </div>
                                <div class="content-main-box-text" style="margin-top: 20px;font-weight: 700;">
                                    图文详情
                                </div>
                                <div class="content-main-box-text" style="margin-top: 10px;display: flex;height: 30px;line-height: 30px;">
                                    <div style="width: 80px;background-color: #d8d8d8;text-align: center">商品名称</div>
                                    <div style="width: 500px;background-color: #efefef;padding: 0 10px;">{{ data.goods_name }}</div>
                                </div>
                                <div class="content-main-box-text" style="display: flex;height: 30px;line-height: 30px;">
                                    <div style="width: 80px;background-color: #d8d8d8;text-align: center">商品规格</div>
                                    <div style="width: 500px;background-color: #efefef;padding: 0 10px;">{{ data.goods_spec }}</div>
                                </div>
                                <div class="content-main-box-text" style="display: flex;height: 30px;line-height: 30px;">
                                    <div style="width: 80px;background-color: #d8d8d8;text-align: center">使用人群</div>
                                    <div style="width: 500px;background-color: #efefef;padding: 0 10px;">所有</div>
                                </div>
                                <div class="content-main-box-text" style="margin-top: 20px;font-weight: 700;">
                                    商品详情 <span class="fuzhi" @click="fuzhi('content')">复制</span>
                                </div>
                                <div v-for="(item, index) in data.goods_info_images">
                                    <el-image :src="item" :preview-src-list="data.goods_info_images" style="width: 100%;" fit="contain">
                                    </el-image>
                                </div>
                                <div class="content-main-box-text" style="margin-top: 10px;font-size: 14px;" v-html="data.content">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="bottom_text">{{ $store.state.text }}</div> -->
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "shopDetails",
    data() {
        return {
            visible: false,
            form: {},
            mainImgUrl: '',
            data: {},
            imgUrlList: [],
            imgActiveIndex: 0, // 当前移动图片的索引值
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            attr_data_id: '',
            cart_number: 1,
            price_profit: '',
            old_price: '',
            login_status: this.$store.state.user.token && this.$store.state.user.is_vip ? true : false,
        };
    },
    computed: {
        imgStyle() {
            return {
                transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            }
        }
    },
    watch: {
    },
    async mounted() {
        // this.$store.commit('settab', 2)
    },
    methods: {
        open(mode = 'add') {
            this.mode = mode;
            this.visible = true;
            return this
        },
        async setData(data) {
            this.form = data
            console.log('this.form', this.form)
            this.price_profit = this.form.price_profit
            this.old_price = this.form.old_price
            this.mainImgUrl = ''
            this.imgUrlList = []
            this.imgActiveIndex = 0 // 当前移动图片的索引值
            this.imgDistance = 0 // 移动的距离
            this.allDistance = 0 // 总移动距离
            this.attr_data_id = ''
            this.cart_number = 1
            this.onList()
            //可以和上面一样单个注入，也可以像下面一样直接合并进去
            //Object.assign(this.form, data)
        },
        fuzhi(field) {
            console.log(field, 'eeeee')
            let a = this.data[field]
            a = a.replace(/<.+?>/g, '');
            a = a.replace(/&nbsp;/ig, '');
            a = a.replace(/&bull;/ig, '');
            a = a.replace(/\s/ig, '');
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value = a;
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            this.$message.success('复制成功!')
            //移除input标签
            document.body.removeChild(input)
        },
        goBack(item) {
            this.$router.push({
                name: 'Index'
            })
        },
        /**
         * 规格选择
         * */
        attrChange(e) {
            this.attr_data_id = e
            this.data.attr_data.map((item, index) => {
                if (this.attr_data_id == item.id) {
                    this.price_profit = item.price_profit
                    this.old_price = item.old_price
                }
            })

        },
        /**
         * event点击
         * */
        eventClick(item) {
            this.attr_data_id = item.id
            this.price_profit = item.price_profit
            this.old_price = item.old_price
            // this.spec = item
        },
        /**
         * 立即购买
         * */
        promptlyPay() {
            if (this.$store.state.user.token && this.$store.state.user.is_vip != 1) {
                this.$message({ message: '会员才可以下单，请联系管理员' });
                return;
            }
            if (this.data.attr_data.length > 0) {
                if (this.attr_data_id == '') {
                    this.$message({ message: '请选择商品规格！' });
                    return;
                }
            }
            let data = []
            let a = {
                goods_name: this.data.goods_name,
                goods_num: this.cart_number,
                price: this.data.price,
                goods_id: this.data.id,
                goods_unit: this.data.unit_id,
                goods_image: this.data.goods_image,
                goods_spec: this.data.goods_spec,
                old_price: this.old_price,
                goods_attr: this.attr_data_id
            }
            data.push(a)
            this.$store.commit('setshop', data);
            this.$router.push('/ConfirmOrder');
        },
        /**
         *  添加
         * */
        btnAdd() {
            this.cart_number++;
        },
        /**
         *  减少
         * */
        btnMinute() {
            if (this.cart_number <= 1) {
                this.$message({ message: '该商品不能减少了！' });
                return;
            }
            this.cart_number--;
        },
        /**
         * 加入购物车
         * */
        addCart() {
            if (this.data.attr_data.length > 0) {
                if (this.attr_data_id == '') {
                    this.$message({ message: '请选择商品规格！' });
                    return;
                }
            }
            if (this.$store.state.user.token && this.$store.state.user.is_vip != 1) {
                this.$message({ message: '会员才可以下单，请联系管理员' });
                return;
            }
            this.$axios.post('api/kuerp/order/index/addCart', this.$qs.stringify({
                goods_id: this.data.id,
                unit_id: this.data.unit_id,
                number: this.cart_number,
                goods_attr: this.attr_data_id
            })).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('加入购物车成功!')
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },

        /**
         * 商品详情
         * */
        onList() {
            this.$axios.post('api/kuerp/order/index/goodsGoodsInfo', {
                id: this.form.id,
            }).then(res => {
                if (res.data.code == 200) {
                    this.data = res.data.data
                    this.price_profit = this.data.price_profit
                    this.old_price = this.data.old_price
                    if (this.data.goods_images.length <= 0) {
                        this.mainImgUrl = this.data.goods_image
                        this.imgUrlList[0] = this.data.goods_image
                    } else {
                        this.imgUrlList = this.data.goods_images
                        //数组开头插入一个元素
                        this.imgUrlList.unshift(this.data.goods_image)
                        this.mainImgUrl = this.imgUrlList[0]
                    }

                }
            })
        },
        changeImg(item, index) {
            this.mainImgUrl = item
            this.imgActiveIndex = index
        },
        imgLeft() {
            if (this.imgActiveIndex > 0) {
                this.imgActiveIndex--  // 索引值-1
                this.imgUrlList.forEach((item, index) => { // 循环小图,通过判断索引值赋值给大图
                    if (this.imgActiveIndex === index) {
                        this.mainImgUrl = item
                    }
                })
            }
            if (this.imgActiveIndex >= 4) {
                var index = 0
                const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
                    if (index < 33) { // 移动次数(33次)
                        this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++
                        return
                    } else {
                        window.clearInterval(temp) // 移动完清除定时器
                    }
                }, 10)
            }
        },
        imgRight() {
            if (this.imgActiveIndex < this.imgUrlList.length - 1) {
                this.imgActiveIndex++
                this.imgUrlList.forEach((item, index) => {
                    if (this.imgActiveIndex == index) {
                        this.mainImgUrl = item
                    }
                })
                if (this.imgActiveIndex >= 5) {
                    this.allDistance = -66 * (this.imgActiveIndex - 4)
                    var index = 0
                    const temp = window.setInterval(() => {
                        if (index < 33) {
                            this.imgDistance -= 2 // 每次向右移动的距离
                            index++
                            return
                        } else {
                            window.clearInterval(temp)
                        }
                    }, 10)
                }
            } else if (this.imgActiveIndex == this.imgUrlList.length - 1) { // 到达最后一张图片，再点击跳转回第一张
                this.imgActiveIndex = 0;
                this.mainImgUrl = this.imgUrlList[0]
                var index = 0
                const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
                    if (index < Math.abs(this.allDistance / 2)) { // 取绝对值再除
                        this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++
                        return
                    } else {
                        window.clearInterval(temp) // 移动完清除定时器
                    }
                }, 1)
            }
        }
    },
}
</script>

<style scoped lang="scss">
.fuzhi {
    width: 0.5rem;
    height: 0.2rem;
    background: #F3F3F3;
    border-radius: 0.02rem;
    text-align: center;
    line-height: 0.2rem;
    font-size: 0.15rem !important;
    font-weight: 400;
    color: #999999;
    margin-left: 0.1rem; //display: block;
    //width: 1rem;
}

.event_box {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: center;

    .eventBox {
        position: relative;
        margin-right: 0.2rem;
    }

    .backgroundxuan {
        background: #EDF3FF;
        border-radius: 0.05rem;
        //font-size: 0.24rem;
        font-weight: 400;
        color: #4B83F0;
    }

    .backgroundnone {
        background: #FFFFFF;
        border-radius: 0.05rem;
        //font-size: 0.24rem;
        font-weight: 400;
        color: #999999;
    }

    >div {
        // width: 216rpx;
        text-align: center;
        // line-height: 110rpx;
        // background: #FFFFFF;
        border-radius: 0.1rem;
        font-size: 0.2rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
        padding: 0.15rem 0.3rem;
    }

}

.button-gwc {
    width: 1.34rem;
    height: 0.5rem;
    background: #4B83F0;
    border-radius: 0.02rem;
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.5rem;
    margin-right: 0.14rem;
    cursor: pointer;
}

::v-deep {
    .el-input--suffix .el-input__inner {
        color: #4B83F0;
    }

    .el-dialog {
        display: flex !important;
        flex-direction: column !important;
        margin: 0 !important;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        overflow-y: auto;
    }

    .el-dialog__body {
        padding: 0 0 0.3rem 0;
    }

    .el-dialog__header {
        padding: 0;
    }
}

.num_input {
    .btn_add {
        background: #4B83F0;
        color: #FFFFFF;
    }

    .input {
        width: 0.4rem !important;
        height: 0.38rem !important;
        line-height: 0.38rem !important;
        text-align: center;
    }

    ::v-deep {
        .el-button {
            width: 0.38rem !important;
            height: 0.38rem !important;
            padding: 0 !important;
        }
    }
}

#index {
    //width: 100%;
    //height: 100vh;
    background: #434655;
}

.content_individual {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    // background: #434655;

    &::-webkit-scrollbar {
        display: none;
    }

    .content_details {
        width: 100%;
        height: 100%;
        // background: #F7F7F7;
        padding: 0.59rem 0.2rem 0.3rem 0.91rem;
        border-radius: 0.04rem;
        font-size: 0.16rem;
        font-weight: 400;
        display: flex;

        .content-main-box {
            margin-left: 3%;

            .content-main-box-text {
                font-size: 0.2rem;
                font-weight: 500;
                color: #333333;
            }

            .mainHeight {
                font-size: 0.14rem;
                font-weight: 400;
                color: #999999;
                line-height: 0.14rem;
            }

            .content-main-box-price {
                font-size: 0.3rem;
                font-weight: normal;
                color: #4B83F0;
            }
        }
    }
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.5rem;
}

.container {
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 1.66rem);

}

.top_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    line-height: 0.2rem;
}

.Img_ul {
    position: relative;
    display: flex;
    top: -0.46rem;
    left: 0.36rem;
    width: 3.22rem;
    height: 1rem;
    overflow: hidden;
    list-style: none;
}

.Img_li {
    float: left;
    margin: 0 0.08rem;
    cursor: pointer;
}

.img_activeBorder {
    border: 0.03rem solid #4B83F0;
}
</style>
